import React from 'react'
import CateHeader from '../header/CateHeader'
import Right from '../header/Right'    
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
const Form = () => {
    const user_id = Cookies.get('userId')
    const [formUrl, setFormUrl] = useState("");
    useEffect(() => {
      const baseUrl = "https://docs.google.com/forms/d/e/1FAIpQLSdR7RD1J0Z1s_3jhhKH2-2pJqVWTotVzHRH3zoc03ZBt8_C-g/viewform?usp=pp_url&embedded=true";
          const dynamicUrl = `${baseUrl}&entry.2044972676=${encodeURIComponent(user_id)}`;
            setFormUrl(dynamicUrl);
    }, []);
  return (
   <>
<div className="section">
      <div className="s_left">
      <CateHeader name={'Contact us'} path={'/'}></CateHeader>
      <iframe  src={formUrl} width="400" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </div>
      <Right></Right>
      </div>   </>
  )
}

export default Form
