import React from 'react'
import dollar from '../../images/celo_dollar.png'
import debit from '../../images/debit.png'
import diamond from '../../images/diamond.png' 
import credit from '../../images/credit.png'
import coin from '../../images/coin.png'
const MyActivity = (props) => {
      return (
    <>
     {
       props.Hist==null && <h6 style={{color:"red",textAlign:"center"}}>No history </h6>
      }
     {props.Hist!=null && 
  props.Hist.map((key,index) => {
    return(
      <div className="ldr_list">
      <div className="ldr_itm">
        <div className='ldr_sb_itm'>
        {key.msg!=null && key.credit_status==1&&key.transaction_type==1 &&
            <img src={credit} alt='credit' />
            }{key.msg!=null && key.credit_status==1&&key.transaction_type==3 &&
              <img src={credit} alt='credit' />
              }
               {key.msg!=null && key.credit_status==1&&key.transaction_type==4 &&
            <img src={credit} alt='credit' />
            }
             {key.msg!=null && key.credit_status==2&& key.transaction_type==1 &&
            <img src={debit} alt='credit' />
            }
         
         {  key.credit_status==1&&key.transaction_type==2 &&
            <img src={credit} alt='credit' />
            }
             { key.credit_status==2&& key.transaction_type==2 &&
            <img src={debit} alt='credit' />
            }
         
          <div>                             
          {key.msg!=null &&  key.credit_status==1&& key.transaction_type==1 &&
            <p className='ldr_sb_itm'>Bought Coin </p>
            }
            {key.msg!=null &&  key.credit_status==2&& key.transaction_type==1 &&
           <div> <p className='ldr_sb_itm'>Deduct Coin </p>
             <p className='ldr_sb_itm g_name_walt'>{key.title}</p>
             </div>
            }
             {  key.credit_status==1&& key.transaction_type==2 &&
              <div><p className='ldr_sb_itm'>Won Diamond </p>
              <p className='ldr_sb_itm g_name_walt'>{key.title}</p>
             </div>            }
             {  key.credit_status==1&& key.transaction_type==3 &&
            <p className='ldr_sb_itm'>cUSD Transferred</p>
            }
            {  key.credit_status==1&& key.transaction_type==4&&
            <p className='ldr_sb_itm'>cUSD Transferring</p>
            }
            { key.credit_status==2&& key.transaction_type==2 &&
            <p className='ldr_sb_itm'>Redeemed Diamonds </p>
            }
            <p className='tming'>{key.create_date}</p>
          </div>
        </div>
        {key.transaction_type==1 &&
         <div className="ldr_sb_itm entry_value">
         <p className="etry_am">
           <img src={coin}alt="diamond"></img>
           {key.diamond_quantity}
         </p>
       </div>
        }
        {key.transaction_type==2&&
         <div className="ldr_sb_itm entry_value">
         <p className="etry_am">
           <img src={diamond}alt="diamond"></img>
           {key.diamond_quantity}
         </p>
       </div>
  }
    {key.transaction_type==3&&
         <div className="ldr_sb_itm entry_value">
         <p className="etry_am">
           <img src={dollar}alt="diamond"></img>
           {key.diamond_quantity}
         </p>
       </div>
        }
        {key.transaction_type==4&&
         <div class="ldr_sb_itm entry_value">
         <p class="etry_am">
          In Process
         </p>
       </div>
        }
      </div>
  </div>
    )
  })
} 
    </>
  )
}

export default MyActivity
