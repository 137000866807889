import Cookies from 'js-cookie';
const COOKIE_NAME = 'userId';
const  COOKIE_TOKE= 'idtoken';
const  Bonus= 'coin';
const url=window.location.href.split('/')[2]; 
const now = new Date();
const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0); // Next midnight
const expiresInMilliseconds = midnight.getTime() - now.getTime(); // Milliseconds until midnight
const expiresInDays = expiresInMilliseconds / (1000 * 60 * 60 * 24); // Convert milliseconds to days
const minu=1;
//genrate random user id
const generateRandomId = (length = 16) => {
  const characters = '1234567899';
  let result = '';
  for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
  }
  return result;
};
// store new user in db 
const ApiCall = async(uid) => {
  try {
    const Url = "https://tapi.gameyze.com/loginorregister";
    const Idtoken ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTY3Nzk5IiwidGltZVN0YW1wIjoiMjAyNC0wNS0xNyAwNTo1NTo0OSJ9.Dkt4VhHOJXINdDxwX1vPVmBQMhJFZ73FkJOBaVTtj78";
    const rawData ={
        "bonus_coin":Cookies.get(Bonus) ,
         "userid":Cookies.get(COOKIE_NAME) ,
        "android_id":"0x7071E17A089Bc6cED07BBAAd75887A318061F8f2",
        "gaid":"0x7071E17A089Bc6cED07BBAAd75887A318061F8f2",
        "mobile_number":"0x7071E17A089Bc6cED07BBAAd75887A318061F8f2",
        "login_type":"3",
        "email_id":"",
        "is_tc_accepted":"Yes",
        "imsi":"132",
        "imei":"1212",
        "brand":"",
        "model":"",
        "operator":"",
        "connection_type":"",
        "apk_version":"33",
        "android_version":"33",
        "package_version":"",
        "fb_first_name":"",
        "fb_last_name":"",
        "fb_email_id":"",
        "fb_image_url":"",
        "fb_id":"",
        "gm_first_name":"",
        "gm_last_name":"",
        "gm_email_id":"",
        "gm_image_url":""
        }
    const response = await fetch(Url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Idtoken}`,
      },
      body: JSON.stringify(rawData),
    });
    if (!response.ok) {
      throw new Error('some error');
    }
    const jsonData = await response.json(); 
    Cookies.set(COOKIE_NAME,jsonData.user_id,); 
    Cookies.set(COOKIE_TOKE,jsonData.idtoken,); 
    Cookies.set(Bonus,jsonData.is_user_registered,{ expires:expiresInDays, }); 
        }
        catch (error) {
            console.error('Error fetching data:', error);
            return 0;
          }
};
export const checkAndHandleUserId  =(navigate) => {
        const userId = generateRandomId();
        const Uid=Cookies.get(COOKIE_NAME)
        const bonus=Cookies.get(Bonus)
        if(!Uid || !bonus){
        ApiCall(userId)
        }
    return {
        useId :Cookies.get(COOKIE_NAME),
        BASE_URL : 'https://tapi.gameyze.com',
        tok :Cookies.get(COOKIE_TOKE) 
    };
  };
let x=checkAndHandleUserId();
    export const BASE_URL=x.BASE_URL
    export const user_id=x.useId
    export const tok=x.tok
