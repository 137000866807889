import React from 'react'
import GameRoute from './GameRoute'
import pop from '../../images/pop_up.jpg'
import cross from '../../images/cross.png'
import { useState, useEffect } from 'react'
import { useCallback } from 'react'
import { checkAndHandleUserId } from '../AllApi/CommonUrl';
import { useNavigate } from 'react-router-dom';
const Home = () => {
  const [isset, Setisset] = useState(false);
  const navigate = useNavigate();
  const fetchData = useCallback(() => {
    if(isset){
    checkAndHandleUserId(navigate);
    Setisset(!isset);
  }
  }, []);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);








  // useEffect(() => {
  //   ; 
  //   let isMounted = true;
  //   (async () => {
  //     if (isMounted) {
  //     checkAndHandleUserId(navigate);
  //     }
  //     //     const elem = document.getElementById('modal1');
  //     // const instance = window.M.Modal.init(elem, { dismissible: false });
  //     // instance.open();
  //   })()
  //   return () => {
  //     isMounted = false;  // cleanup when component unmounts
  //   };
  // },[1]);
  return (
    <>
      <GameRoute></GameRoute>
    </>
  )
}

export default Home
