import React from 'react'
import CateHeader from '../header/CateHeader'
import Right from '../header/Right'    
import MyVideo from "../../images/Watchvideo.mp4";

const Video = () => {
  return (
   <>
      <div className="section">
      <div className="s_left">
      <CateHeader name={'Watch Video'} path={'/gameroute/1'}></CateHeader>

      <video width="100%" height="100%"  preload='auto' controls loop autoPlay>
        <source src={MyVideo} type="video/mp4" />
        Your browser does not support HTML5 video.
        </video>
      </div>
      <Right></Right>
      </div>
   </>
  )
}

export default Video
