
// const BASE_URL = 'http://fantasyapi.mchamp.com/';
export const SelfUser = async (id,BASE_URL,contest,endpoint,Contesttype,tok) => {
    try {
      const Url = `${BASE_URL}/${endpoint}`;
    const Idtoken =`${tok}`;
    const rawData ={
      "user_id":id,
      "contest_id" :contest,
      "contest_type":Contesttype
  }
    const response = await fetch(Url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Idtoken': `${Idtoken}`,
      },
      body: JSON.stringify(rawData),
    });
    if (!response.ok) {
      throw new Error('some error');
    }
    const jsonData = await response.json();
    return jsonData;
        } catch (error) {
      console.error('Error fetching data:', error);
      return 0;
    }
  };
  
