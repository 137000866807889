import React from 'react'
import CateHeader from '../header/CateHeader'
import dollar from '../../images/celo_dollar.png'
import debit from '../../images/debit.png'
import diamond from '../../images/diamond.png' 
import credit from '../../images/credit.png'
import coin from '../../images/coin.png'
import { useEffect, useState } from 'react'
import { History } from '../AllApi/History';
import { BASE_URL,tok} from '../AllApi/CommonUrl';
import { gameData } from '../AllApi/GameApi';
import Cookies from 'js-cookie';
let { id } = 214;
const ContestPlayed = (props) => {
    const [arr, setArr] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        ; (async () => {
          try {
            const gameEnd='goldgame/gameContestList'
            const game = await gameData(id,0,'0x7071e17a089bc6ced07bbaad75887a318061f8f2',BASE_URL,gameEnd,tok);
            setArr(game.result.game_details)
            setIsLoading(false)
                    } catch (error) {
            console.log(error)
          }
        })()
      }, []);
  return (
   <>
    {
       props.arr==null && <h6 style={{color:"red",textAlign:"center"}}>No history </h6>
      }
      <div className="ldr_list bg_none">
          <div className="ldr_itm">
            <div className='ldr_new'>
              <div className="ldr_sb_itm">
                <p>Rank</p>
              </div>
              <div className="ldr_sb_itm">
                <p>Contest Name</p>
              </div>

            </div>
            <div className="ldr_itm">
              <p>
                <span>Diamonds Won</span>
              </p>
            </div>
          </div>
        </div>
     { props.arr!=null && 
   props.arr.map((key,index) => {
    return(
        <div className="ldr_list">
        <div className="ldr_itm">
          <div className='ldr_sb_itm gape'>
          {!key.rank&&
              <p>NR</p> 
              }  
               {key.rank&&
              <p>#{key.rank}</p> 
              }          
            <div>                             
              {
             <div> <p className='ldr_sb_itm'>{key.title}</p>
               <p className='ldr_sb_itm g_name_walt'>{key.create_date}</p>
               </div>
              }
            </div>
          </div>
          {key.rank&&
           <div className="ldr_sb_itm entry_value">
           <p className="etry_am">
             <img src={diamond}alt="diamond"></img>
             {key.diamond_quantity}
           </p>
         </div>
    }
     {!key.rank&&
           <div className="ldr_sb_itm entry_value">
           <p className="etry_am">
             <img src={diamond}alt="diamond"></img>
           <p>0</p>
           </p>
         </div>
    }
        </div>
    </div>
    )
  })
} 
   </>
  )
}

export default ContestPlayed
