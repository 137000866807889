import React from 'react'
const ActiveContest = (props) => {
  return (
   <>
    {
       props.arr==null && <h6 style={{color:"red",textAlign:"center"}}>No history </h6>
      }
      <div className="ldr_list bg_none">
          <div className="ldr_itm">
            <div className='ldr_new'>
              <div className="ldr_sb_itm">
                <p>Rank</p>
              </div>
              <div className="ldr_sb_itm">
                <p>Contest Name</p>
              </div>

            </div>
            <div className="ldr_itm">
              <p>
                <span>Winners Announced In</span>
              </p>
            </div>
          </div>
        </div>
     {props.arr!=null && 
  props.arr.map((key,index) => {
    return(
        <div className="ldr_list">
        <div className="ldr_itm">
          <div className='ldr_sb_itm gape'>
          {
              <p>#-</p>
              
              }          
            <div>                             
              {
             <div> <p className='ldr_sb_itm'>{key.title}</p>
               <p className='ldr_sb_itm g_name_walt'>{key.create_date}</p>
               </div>
              }
            </div>
          </div>
          {
             <div className="ldr_sb_itm entry_value">
               <p className="etry_am timer_reverse">{key.announce_date}, 01:15 AM
     </p>
          </div>
    }
        </div>
    </div>
    )
  })
} 
   </>
  )
}

export default ActiveContest
