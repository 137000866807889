import React from 'react'
import Profile from '../User/Profile'
import { ProfileData } from '../AllApi/UserProfileApi';
import Cookies from 'js-cookie';
import { BASE_URL } from '../AllApi/CommonUrl';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import loader from '../../images/loader.gif'
import Right from '../header/Right'
import CateHeader from '../header/CateHeader'
import Swal from 'sweetalert2';
const UserProfileRoute = () => {
  const user_id = Cookies.get('userId')
  const tok = typeof (Cookies.get('logintoken')) === "undefined" || Cookies.get('logintoken') == "" ? Cookies.get('idtoken') : Cookies.get('logintoken')
  const [daimond,setDaimond] = useState(0 );
  const navigate = useNavigate()
  const [Coin,setCoin] = useState(0);
  const [PlayedContest,setPlayedContest] = useState(null);
  const [cUsd, setCusd] = useState(0);
  const [Hist, setHist] = useState([]);
  const [name, setName] = useState(null);
  const [profilepic, setProfilepic] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setuser] = useState([]);
  const [Active, setActive] = useState(null);
  const Internet=()=>{
    Swal.fire({
      title: "Network issue",
      text:"Weak  internet connection",
      allowOutsideClick: false,
      icon:'error'
    });
  }
  useEffect(() => {
    ; (async () => {
      try {
        if (!user_id) {
          navigate("/");
        }
        const gameEnd='Userheaderdetail/userProfileData'
        const User = await ProfileData(user_id,BASE_URL,gameEnd,tok);
        if(User==0){
          Internet();
         }
        if(User.status==0){
          navigate('/');
        }
        setActive(User.result.everyHourPlayed)
        setDaimond(User.result.profile.diamond_balance)  
        setCusd(User.result.cUSDEarn.totalcUSD) 
        setCoin(User.result.profile.coin_balance)   
        setPlayedContest(User.result.gameEarnDiamond)  
        setName(User.result.profile.user_name)
        setuser(User.result.profile.android_id)
        setProfilepic(User.result.profile.user_image)
        setHist(User.result.history)
        setIsLoading(false);
            } catch (error) {
        console.log(error)
      }
    })()
  }, []);
  // console.log('contest playd',Active)
  return (
    <>
          <div className="section">
   <div className="s_left">
    <CateHeader name={'My Profile'}path={'/gameroute/1'} ></CateHeader>
    {isLoading &&
            <div className='center_vh'>
              <img src={loader} alt="loader"></img>
            </div>
}
          {!isLoading &&
 <Profile daimond={daimond}cUsd={cUsd}Coin={Coin}Hist={Hist}PlayedContest={PlayedContest}name={name} user={user}Active={Active} profilepic={profilepic}></Profile>
          }
          </div>
          <Right></Right>
          </div>
 </>
  )
}

export default UserProfileRoute
