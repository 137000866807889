import React from 'react'
import { gamescore } from '../AllApi/GameScore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import Right from '../header/Right';
import { BASE_URL} from '../AllApi/CommonUrl';
import { useNavigate } from "react-router-dom";
import l1 from '../../images/level1.png'
import l2 from '../../images/level2.png'
import l3 from '../../images/level3.png'
import l4 from '../../images/level4.png'
import l5 from '../../images/level5.png'
import Cookies from 'js-cookie';
import COMPLETE from '../../images/COMPLETED.gif'
import QuizFooter from '../footer/QuizFooter';
import Swal from 'sweetalert2';
const GamePlay = () => {
  const [cross, setCross] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [Totalsocre, SetTotalsocre] = useState(0);
  const toggleButton = () => {
    setShowButton(!showButton);
  };
  let { user_id, contest_id, level_id, score,time ,quiz } = useParams();
  const isQuiz=quiz==null?0:quiz;
  const timer=time==null?12000:time
  const newtime=timer>30000?0:timer
  const time1=contest_id==210?time:newtime
   const tok=Cookies.get('idtoken')
  const navigate = useNavigate()
  const res = async() => {
    if(isQuiz==1){
      navigate(`/GameDetails/${contest_id}`);
    }
    else{
      navigate(`/GameLevel/${contest_id}`);
    }
  }

  const Internet=()=>{
    Swal.fire({
      title: "Network issue",
      text:"Weak  internet connection",
      allowOutsideClick: false,
      icon:'error'
    });
  }
  useEffect(() => {
    ; (async () => {
      setTimeout(function () {
        setCross(true)
      }, 5000);

      if (!user_id) {
        navigate("/");
      }
      try {
        if(score!=0&&time!=0){
        const EndPoint = '/goldgame/savelevelPoints'
        const result = await gamescore(user_id, contest_id, level_id, score, BASE_URL, EndPoint,tok,Math.round((parseInt(time1))/60));
        if(result==0){
          Internet();
         }
         SetTotalsocre(result.totalScore)}
      } catch (error) {
        console.log(error)
      }
    })()
  }, []);
  return (
    <>
      <div className="section">
        <div className="s_left">
          <div className="level_complete">
          {level_id == 1 && isQuiz == 0 &&
              <img src={l1} alt="level1" />
            }
            {level_id == 2 && isQuiz == 0 &&
              <img src={l2} alt="level1" />
            }
            {level_id == 3 && isQuiz == 0 &&
              <img src={l3} alt="level1" />
            }
            {level_id == 4 && isQuiz == 0 &&
              <img src={l4} alt="level1" />
            }
            {level_id == 5 && isQuiz == 0 &&
              <img src={l5} alt="level1" />
            }
            {
              isQuiz == 1 &&
              <img src={COMPLETE} alt="level1" className='compl_gif' />
            }
            <h2>Completed</h2>
            {isQuiz == 0 && <p>Click Next Button to Play Next Level</p>}
            {isQuiz == 0 && <p className="margin10 scre">Level Score:<span>{(parseInt(score) + Math.round(parseInt(newtime) / 60))}</span></p>}
            {isQuiz == 1 && <p className="margin10 scre"> Score:<span>{Totalsocre}</span></p>}
            {isQuiz == 0 && <button className="btn_white btn_gren" onClick={res}>Next</button>}
            {isQuiz == 1 &&
              <div className="mr_20">
                <p className="game_desc desc2 center">Thanks for Completing
                  <span className='game_desc '>The Winner will be Announced after Tournament End Time.</span>
                </p>
              </div>
            }
            {isQuiz == 1 && <button className="btn_white btn_gren" onClick={res}>Leaderboard</button>}
          </div>
        </div>
        <Right></Right>
      </div>
    </> 
  )
}
export default GamePlay
