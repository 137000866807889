import React from 'react'
import Right from '../header/Right'
import CateHeader from '../header/CateHeader'
import { NavLink } from 'react-router-dom'
import QuizFooter from '../footer/QuizFooter'
const HowPlay = () => {
    return (
        <>
            <div className="section">
                <div className="s_left">
                    <div className="header">
                            <CateHeader name={'How to Play'} path={'/gameroute/1'}></CateHeader>
                    </div>
                    {/* how to play content*/}
                    <div className="htplay game_det">
                        <h2 className="text_bg redd">How to Play</h2>
                        <div className="htP_text">
                            <p>1. Each Game Tournament Can be played once daily.</p>
                            <p>2. To participate in the tournament user needs 500 coins.</p>
                            <p>3. The coins can be purchased from the wallet section, by using the cUSD.
                            </p>
                            <p>4. Each Game Tournament has five levels, the user must complete all five levels.
                            </p>
                            <p>5. Total score from the five levels, will be added and considered as final score.
                            </p>
                            <p>6. When the Game tournament time ends, the leaderboard is created based on the user score.
                            </p>
                            <p>7. The winning users will get diamonds, as per the Prize distribution for the Game Tournament.
                            </p>
                            <p>8. The winning Diamonds can be further, redeemed back into cUSD.</p>
                        </div>
                    </div>
                    <QuizFooter></QuizFooter>
                </div>
                <Right></Right>
            </div>

        </>
    )
}

export default HowPlay
