import React from 'react'
import dd from '../../images/diamond.png'
import info from '../../images/info.svg'
import users from '../../images/user.svg'
import loader from '../../images/loader.gif'
import CateHeader from '../header/CateHeader'
import calender from '../../images/calender.svg'
import { useState, useEffect } from 'react';
import Right from '../header/Right'
import { useParams } from 'react-router-dom'
import { Singlegame } from '../AllApi/SingleGameApi';
import WonBreak from './WonBreak';
import PreWinn from './PreWinn';
import LeadBoard from './LeadBoard';
import { walletData } from '../AllApi/WalletApi';
import { useNavigate } from "react-router-dom";
import { CoinData } from '../AllApi/CoinDeductionApi';
import { PlayOr } from '../AllApi/PlayOrNot';
import { BASE_URL } from '../AllApi/CommonUrl';
import { Details } from '../AllApi/GameDetailsApi';
import Cookies from 'js-cookie';
import QuizFooter from '../footer/QuizFooter';
import { LeaderData } from '../AllApi/LeaderApi';
import { PrevData } from '../AllApi/PrevApi';
import { checkAndHandleUserId } from '../AllApi/CommonUrl';
import Swal from 'sweetalert2';
const GameDetails = () => {
  const url = window.location.href.split('/')[2];
  const [Value, setValue] = useState(0);
  const [play, setPlay] = useState(null);
  const [arr, setArr] = useState([]);
  const [avlcoins, setAvlcoins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ClickValue, SetClickvalue] = useState(false);
  const [winn, setWinn] = useState([]);
  const [pre_winners, setPre_winners] = useState([]);
  const [winstatus, setwinstatus] = useState();
  const [winning_message, Setwinning_message] = useState();
  const [lead, setLead] = useState([]);
  let { contest } = useParams();
  const entry_fees = arr.contest_cost
  const navigate = useNavigate()
  const user_id = Cookies.get('userId')
  const tok = typeof (Cookies.get('logintoken')) === "undefined" || Cookies.get('logintoken') == "" ? Cookies.get('idtoken') : Cookies.get('logintoken')
  const BalanceAlert = () => {
    Swal.fire({
      title: "You Don't Have Enough Coin",
      showDenyButton: false,
      confirmButtonText: "Ok",
      denyButtonText: `Not Now`,
      allowOutsideClick: false
    // }).then((result) => {
    //   /* Read more about isConfirmed, isDenied below */
    //   if (result.isConfirmed) {
    //     navigate(`/progress/${1}`);
    //   }
    //   else if (result.isDenied) {
    //     SetClickvalue(false);
    //   }
    });
  }
  const DeductCoin = async () => {
      try {
        const endpoint = 'userheaderdetail/coinDeduct';
        const result = await CoinData(user_id, entry_fees, arr.contest_name, BASE_URL, contest, endpoint, tok);
        if(result.status==1){
          if(arr.level_status==2){
            var link = `${arr.game_url}?userId=${user_id}&contestId=${contest}&levelNameId=${1}&newurl=https://${url}/GamePlay`
            window.location.href = link;
          }
          else{
            navigate(`/GameLevel/${contest}`);
          }
      }
        else{ SetClickvalue(false);}
      }
      catch { }
  }
  const CoinDeduct = () => {
    Swal.fire({
      title: "Rules",
      html: `<ul class="list_def"><li>${entry_fees} coins will be deducted from the User’s account.</li><li>The user must play all the five levels.  
      </li><li>Score will be based on the sum of all five levels score. 
</li><li>current rank will be decided as per the current no of user played.</li><li>The leaderboard will be updated every one hour. 
</li><li>The final rank will be announced after completion of the tournament and the rank can be checked on the previous winner as well as on the User’s profile section.
</li><li>For any queries please contact us at hello@mchamp.com</li></ul>
            `,
      showDenyButton: true,
      confirmButtonText: "Continue",
      denyButtonText: `Cancel`,
      allowOutsideClick: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        SetClickvalue(true);
        DeductCoin()
      }
     
    });
  }
  const SuccessHandler=()=>{
    Swal.fire({
      title: "Rules",
      html: `<ul class="list_def"><li>${entry_fees} coins will be deducted from the User’s account.</li><li>The user must play all the five levels.  
      </li><li>Score will be based on the sum of all five levels score. 
</li><li>current rank will be decided as per the current no of user played.</li><li>The leaderboard will be updated every one hour. 
</li><li>The final rank will be announced after completion of the tournament and the rank can be checked on the previous winner as well as on the User’s profile section.
</li><li>For any queries please contact us at hello@mchamp.com</li></ul>
            `,
      allowOutsideClick: false,
      showConfirmButton:false,
      showCancelButton:true
    });
  }
  const res = async () => {
    if (play == 1) {
      if(arr.level_status==2){
        var link = `${arr.game_url}?userId=${user_id}&contestId=${contest}&levelNameId=${1}&newurl=https://${url}/GamePlay`
        window.location.href = link;
      }
      else{ navigate(`/GameLevel/${contest}`);}
    }
    else if(parseInt(avlcoins)>= parseInt(entry_fees)) {
      CoinDeduct()
    }
    else if(parseInt(avlcoins)< parseInt(entry_fees)) {
      BalanceAlert()
    }
  }
  useEffect(() => {
    ; (async () => {
      try {
        if (!user_id) {
          navigate("/");
        }
        const endpoint = 'offer/playedOrNot/';
        const playornot = await PlayOr(user_id, entry_fees,arr.contest_name, BASE_URL, contest, endpoint, tok);
        setPlay(playornot.result)
        setIsLoading(false);
      } catch (error) {
        console.log(error)
      }
    })()
  }, []);
  const Internet=()=>{
    Swal.fire({
      title: "Network issue",
      text:"Weak  internet connection",
      allowOutsideClick: false,
      icon:'error'
    });
  }
  useEffect(() => {
    ; (async () => {
      try {
        if (!user_id) {
          navigate("/");
        }
        const gameEnd = 'goldgame/gameSingleData'
        const game = await Singlegame(contest, user_id, BASE_URL, gameEnd, tok);
        if(game==0){
          Internet();
         }
        if(game.status==0){
          checkAndHandleUserId(navigate);
        }
        setArr(game.result.game_details[0])
      } catch (error) {
        console.log(error)
      }
    })()
  }, []);
  useEffect(() => {
    ; (async () => {
      try {
        if (!user_id) {
          navigate("/");
        }
        const WalletEndPoint = 'userheaderdetail/homecategory'
        const result = await walletData(user_id, BASE_URL, WalletEndPoint, tok); // Replace with your endpoint
        setAvlcoins(result.data.wallet.coin_balance)
        setIsLoading(false);
      } catch (error) {
        console.log(error)
      }
    })()
  }, []);
  useEffect(() => {
    ; (async () => {
      try {
        if (!user_id) {
          navigate("/");
        }
        const EndPoint = 'offer/contestWiseLeaderBoard'
        const leader = await LeaderData(contest, user_id, BASE_URL, EndPoint, tok);
        setLead(leader.result)
        setIsLoading(false);
      } catch (error) {
        console.log(error)
      }
    })()
  }, []);
  useEffect(() => {
    ; (async () => {
      try {
        if (!user_id) {
          navigate("/");
        }
        const PrevEnd = 'offer/previousContestWiseLeaderBoard'
        const previous = await PrevData(contest,user_id, BASE_URL, PrevEnd,tok);
        setPre_winners(previous.result)
        setIsLoading(false);
      } catch (error) {
        console.log(error)
      }
    })()
  }, []);

  useEffect(() => {
    ; (async () => {
      try {
        if (!user_id) {
          navigate("/");
        }
        const gameendpoint = 'goldgame/gameDetail';
        const gamedetail = await Details(contest,user_id, BASE_URL, gameendpoint,tok);
        setwinstatus(gamedetail.winning_status)
        Setwinning_message(gamedetail.winning_message)
        setWinn(gamedetail.result.winbreakup)
        setIsLoading(false);
      } catch (error) {
        console.log(error)
      }
    })()
  }, []);
  return (
    <>
      <div className="section">
        <div className="s_left">
   <CateHeader name={arr.contest_name} path={'/gameroute/1'}></CateHeader>
          {isLoading &&
            <div className='center_vh'>
              <img src={loader} alt="loader"></img>
            </div>

          }
          {!isLoading &&

<div className="game_detail2">
<div className="g_tp_img">
  <img src={arr.image_url } />
</div>
<div className="g_if_desc">
  <div className="w_70">
    <p className="game_ttl">{arr.contest_name}</p>
    <p className="game_desc">
      {arr.games_discription}
    </p>
  </div>
  <div>
  {arr.joinbuttontxt=="Completed" &&<button class="btn_white btn_gren disable" > {arr.joinbuttontxt}</button>

}
 {arr.joinbuttontxt=="Play Now" &&ClickValue==false&&<button class="btn_white btn_gren" onClick={res}>Play Now</button>

  }
   {arr.joinbuttontxt=="Play Now" &&ClickValue==true&&<button class="btn_white btn_gren disable">Play Now</button>

}
  </div>
</div>
<p className="cont_end">
  Contest Ends In: <img src={calender} />
  <span>{arr.contest_played_date}(GMT +01:00)</span>
</p>
<div className="game_details2">
  <div>
    <p className="l_infogam">Prize Pool</p>
    <p className="r_infogam">
      <img src={dd} alt="diamond" />
      {arr.prize_pool}
    </p>
  </div>
  <div>
    <p className="l_infogam l_info_img">Entry Fee <img src={info} alt="coin" onClick={SuccessHandler} /></p>

    <p className="r_infogam">
      <img src={arr.entry_icon} alt="coin"/>
    {arr.contest_cost}
    </p>
  </div>
  <div>
  <p className="l_infogam">Participants</p>
    <p className="r_infogam">
      <img src={users} alt="calender" />
     {arr.total_user_playeds}
    </p>
  </div>
</div>
{/*toggle*/}
<div className="tab_n mrgin_10_tb">
  <div className='toggle_btn'>
    <button className={`${(Value !=2 && Value !=3)  ? 'act_btn' : ''}`} onClick={() => setValue(1)}>Leaderboard</button>
    <button className={`${Value == 2 ? 'act_btn' : ''}`} onClick={() => setValue(2)}>Prev. Winners</button>
    <button className={`${(Value ==3)  ? 'act_btn' : ''}`} onClick={() => setValue(3)}>Winning Breakup</button>
  </div>
</div>
{(Value !==2&& Value !==3)&&
              <LeadBoard type={1} contest={contest} lead={lead}></LeadBoard>
            }
             {Value === 2 &&
              <PreWinn contest={contest} prev={pre_winners}></PreWinn>
            }
  {(Value==3)&&
              <WonBreak winn={winn} staus={winstatus} message={winning_message}></WonBreak>
            }
            <div className="btm_fix">

{arr.joinbuttontxt=="Completed" &&<button class="btn_white btn_gren disable " > {arr.joinbuttontxt}</button>

          }
           {arr.joinbuttontxt=="Play Now" &&ClickValue==false&&<button class="btn_white btn_gren" onClick={res}>Play Now</button>

            }
             {arr.joinbuttontxt=="Play Now" &&ClickValue==true&&<button class="btn_white btn_gren disable">Play Now</button>

}

</div>
<QuizFooter></QuizFooter>

</div>
          }
          
        </div>
        <Right></Right>
      </div>
    </>
  )
}
export default GameDetails
