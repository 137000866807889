import React from 'react'
import { BASE_URL} from '../AllApi/CommonUrl';
import dd from '../../images/diamond.png'
import { useState, useEffect } from 'react';
import double from '../../images/2X.svg'
import Cookies from 'js-cookie';
const WonBreak = (props) => {
  const winn=props.winn
  return (
    <>
      <div id="test-swipe-1" className="col s12">
      <p className="game_ttl">Winnings Breakup</p>
       {props.staus==1&& <div className="ldr_list">
          <div className="ldr_itm">
            <div className="ldr_sb_itm">
              <p>Rank</p>
            </div>
            <div className="ldr_sb_itm">
              <p>Diamonds</p>
            </div>
          </div>
        </div>}
        {props.staus==2&& <div className="">
        <div className="new_msg">
          <img src={double} alt={double} className='new_msg_img'/>
          <p class="game_ttl">{props.message}</p>
        </div>
    </div>}
{winn!=null &&props.staus==1&&
  winn.map((key,index) => {
    return(
      <div className="ldr_list" key={index}>
      <div className="ldr_itm">
        <div className="ldr_sb_itm">
        <p>#{key.rank}</p>
        </div>
        <div className="ldr_sb_itm">
        <p>
          <img src={dd} alt="Daimond" /> {key.winning}
        </p>
        </div>
      </div>
    </div>
    )
  })
} 
      </div>

    </>
  )
}

export default WonBreak
