
// const BASE_URL = 'http://fantasyapi.mchamp.com/';
 export const CoinAdd = async (user_id,quantity,BASE_URL,endpoint,tok) => {
  console.log('Apitok',tok)
        console.log('ApiIDD',user_id)
    try {
      const Url = `${BASE_URL}/${endpoint}`;
    const Idtoken =`${tok}`;
    const rawData = {
            "user_id":user_id,
            "money_amount":quantity,
            "ip": "123.34.34"
   }
    const response = await fetch(Url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Idtoken': `${Idtoken}`,
      },
      body: JSON.stringify(rawData),
    });
    if (!response.ok) {
      throw new Error('some error');
    }
    const jsonData = await response.json();  
    console.log(jsonData)
    return jsonData;
        } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  