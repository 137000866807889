import React from 'react'
import rest from '../../images/rest.png'
import Right from '../header/Right'
import CateHeader from '../header/CateHeader'
const Restricted = () => {
  return (
   <> 
   <div className="section">
            <div className="s_left">
                <CateHeader name={'Restricted'} path={'/'}></CateHeader>
                    <div class="center err">
                        <img src={rest} class="top_img_bane err_imag" alt=""></img>
                        <p class="l_ttl">This is restricted page!</p>
                       
                    </div>
                </div>
            <Right></Right>
        </div>
   
   </>
  )
}

export default Restricted
