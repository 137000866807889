import React from 'react'
import { BASE_URL } from '../AllApi/CommonUrl';
import Cookies from 'js-cookie';
import { CoinAdd } from '../AllApi/AddCoin';
import { useState } from 'react';
import dollar from '../../images/celo_dollar.png'
import { useNavigate } from "react-router-dom";
import { Redeme } from '../AllApi/ReedemNow';
import loader from '../../images/loader.gif'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
const COOKIE_NAME = 'userId';
const COOKIE_TOKE = 'idtoken';
// const { getContract, formatEther, createPublicClient, http } = require("viem");
// const { celo } = require("viem/chains");
// const { stableTokenABI } = require("@celo/abis");
const tok = Cookies.get('idtoken')
const WalletInfo = (props) => {
  let { flag } = useParams();
  const [adddress, setAddress] = useState('');
  const [Buy, SetBuy] = useState(false);
  const [CoinValue, SetCoinValue] = useState(0);
  const [ClickValue, SetClickvalue] = useState(false);
  const [load, SetLoad] = useState(false);
  const navigate = useNavigate()
  const generateRandomId = async () => {
    if (window && window.ethereum) {

      if (window.ethereum.isMiniPay) {
        // User is using Minipay

        // Requesting account addresses
        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [],
        });
        // Injected wallets inject all available addresses,
        // to comply with API Minipay injects one address but in the form of array
        //console.log(accounts[0]);
        return accounts[0];
      }
    }

  };
  // store new user in db 
  const ApiCall = async (uid) => {
    try {
      const Url = "https://tournamentapi.mchamp.xyz/loginorregister";
      const Idtoken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTY3Nzk5IiwidGltZVN0YW1wIjoiMjAyNC0wNS0xNyAwNTo1NTo0OSJ9.Dkt4VhHOJXINdDxwX1vPVmBQMhJFZ73FkJOBaVTtj78";
      const rawData = {
        "android_id": uid,
        "gaid": uid,
        "mobile_number": uid,
        "login_type": "3",
        "email_id": "",
        "is_tc_accepted": "Yes",
        "imsi": "132",
        "imei": "1212",
        "brand": "",
        "model": "",
        "operator": "",
        "connection_type": "",
        "apk_version": "33",
        "android_version": "33",
        "package_version": "",
        "fb_first_name": "",
        "fb_last_name": "",
        "fb_email_id": "",
        "fb_image_url": "",
        "fb_id": "",
        "gm_first_name": "",
        "gm_last_name": "",
        "gm_email_id": "",
        "gm_image_url": ""
      }
      const response = await fetch(Url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Idtoken}`,
        },
        body: JSON.stringify(rawData),
      });
      if (!response.ok) {
        throw new Error('some error');
      }
      const jsonData = await response.json();
      Cookies.set(COOKIE_NAME, jsonData.user_id, { expires: 1 });
      Cookies.set(COOKIE_TOKE, jsonData.idtoken, { expires: 1 });
    }
    catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }

  };
  const checkAndHandleUserId = () => {
    const myFirstPromise = new Promise((resolve, reject) => {
      resolve(generateRandomId())
    });
    myFirstPromise.then((successMessage) => {
      if (typeof navigate !== 'function') {
        console.error("Navigate is not a function");
        return;
      }
      if (typeof (successMessage) == 'undefined') {
        navigate('/restricted')
      }
      else {
        ApiCall(successMessage)
      }
    });
  };
  const user_id = props.user_id
  if (!user_id) {
    checkAndHandleUserId()
  }
  const SuccessHandler = () => {
    Swal.fire({
      title: "Transaction Successfull!",
      text: "Congratulations you got Coins",
      icon: "success",
      allowOutsideClick: false,
      showConfirmButton: true
    });
  }


  const popup = () => {
    const inputOptions = new Promise((resolve) => {
      resolve({
        "500": "500 Coin       (Price: 0.02cUSD)",
        "1000": "1000 Coin     (Price: 0.04cUSD)",
        "2000": "2000 Coin     (Price: 0.08cUSD)",
        "3000": "3000 Coin     (Price: 0.12cUSD)",
        "5000": "5000 Coin     (Price: 0.2cUSD)",
        "10000": "10000 Coin   (Price: 0.4cUSD)",

      });
    });
    const { value: Coin } = Swal.fire({
      title: "Select Coins",
      input: "radio",
      showCancelButton: true,
      inputOptions,
      inputValue: "500",
      inputValidator: (value) => {
        SetCoinValue(value)
        BuyCoin(value);
        if (!value) {
          return "You need to choose something!";
        }
      }
    });
  }


  const Test = () => {
    const radioOptions = {
      500: {
        title: '500',
        desc: 'Price: 0.02 cUSD'
      },
      1000: {
        title: '1000',
        desc: 'Price: 0.04 cUSD'
      },
      2000: {
        title: '2000',
        desc: 'Price: 0.08 cUSD'
      },
      3000: {
        title: '3000',
        desc: 'Price: 0.12 cUSD'
      },
      5000: {
        title: '5000',
        desc: 'Price: 0.2 cUSD'
      },
      10000: {
        title: '10000',
        desc: 'Price: 0.4 cUSD'
      }
    };
    const defaultValue = '500';
    function generateRadioHTML(options) {
      let html = '<div class="my-radio-inputs">';
      for (const [value, option] of Object.entries(options)) {
        const isChecked = value === defaultValue ? 'checked' : '';
        const selectedClass = value === defaultValue ? 'selected' : '';
        html += `
        <label class="label_cust ${selectedClass}">
          <input name="group1" type="radio" value="${value}" ${isChecked} />
          <div class="div_labe">
            <p class="title_lab">${option.title}</p>
            <p class="desc_lab">${option.desc}</p>
          </div>
        </label>`;
      }
      html += '</div>';
      return html;
    }
    const { value: selectedValue } = Swal.fire({
      title: 'Select Coins',
      html: `
      <div class="my-custom-content">
        ${generateRadioHTML(radioOptions)}
      </div>`,
      showCancelButton: true,
      confirmButtonText: "Proceed",
      inputValue: "500",
      didOpen: () => {
        // Step 3.1: Add event listeners to all radio buttons
        const radioButtons = document.querySelectorAll('input[name="group1"]');
        radioButtons.forEach((radio) => {
          radio.addEventListener('change', () => {
            // Remove the custom class from all labels
            document.querySelectorAll('.label_cust').forEach((label) => {
              label.classList.remove('selected');
            });
            // Add the custom class to the parent label of the checked radio
            radio.parentElement.classList.add('selected');
          });
        });
      },
      preConfirm: () => {
        // Retrieve the selected radio button value
        const selectedRadio = document.querySelector('input[name="group1"]:checked');
        const res = selectedRadio ? selectedRadio.value : null
        BuyCoin(res);
      },
    });
  }


  const FailHanler = (message) => {
    Swal.fire({
      icon: "error",
      title: message,
      text: "Something went wrong!",
      allowOutsideClick: false,
      showConfirmButton: false
      // footer: '<Navlink to={'/'}>Why do I have this issue?</Navlink>'
    });
  }

  const res = async (quantity) => {
    try {
      const endpoint = 'offer/userCoinBuy/'
      const result = await CoinAdd(user_id, 100, BASE_URL, endpoint, props.tok);
      console.log('redeem f', result);
      alert(result.message)
      SuccessHandler()
      window.location.reload();
      navigate("/Progress");
    }
    catch { }
  }
  // const STABLE_TOKEN_ADDRESS = "0x765DE816845861e75A25fCA122bb6898B8B1282a";
  // const CheckBalance=async(publicClient, address)=>{
  //   let StableTokenContract = getContract({
  //     abi: stableTokenABI,
  //     address: STABLE_TOKEN_ADDRESS,
  //     publicClient,
  // });

  // let balanceInBigNumber = await StableTokenContract.read.balanceOf([
  //     address,
  // ]);

  // let balanceInWei = balanceInBigNumber.toString();

  // let balanceInEthers = formatEther(balanceInWei);

  // return balanceInEthers;

  // }
  // const publicClient = createPublicClient({
  //   chain: celo,
  //   transport: http(),
  // }); // Mainnet
  // const GetAddress = async() => {
  //   if (window && window.ethereum) {

  //     if (window.ethereum.isMiniPay) {
  //       // User is using Minipay

  //       // Requesting account addresses
  //       let accounts = await window.ethereum.request({
  //         method: "eth_requestAccounts",
  //         params: [],
  //       });
  //       // Injected wallets inject all available addresses,
  //       // to comply with API Minipay injects one address but in the form of array
  //       //console.log(accounts[0]);
  //        return accounts[0];
  //     }
  //     }
  // };
  const BuyCoin = (coinsvval) => {
    const balance = (!coinsvval) ? 0.02 : (coinsvval / 500) * .02
    res(balance);
    SetClickvalue(true);
    SetLoad(true)
    // FailHanler('Transection Failed...');
    if (flag == 1) {
      navigate(-1);
    }
    else {
      navigate(`/progress/${1}`);
    }

    const myFirstPromise = new Promise((resolve, reject) => {
      // resolve( GetAddress())
    });
    myFirstPromise.then((successMessage) => {
      setAddress(successMessage);
      const resPromis = new Promise((resolve, reject) => {
        //   resolve( CheckBalance(publicClient,successMessage))
      });
      resPromis.then((totbalance) => {
        if (totbalance >= (balance)) {
          const deduct = new Promise((resolve, reject) => {
            // resolve(AmountDeduct(adddress,totbalance,.001))
          });
          deduct.then((message) => {
            alert(message)
            if (message === "success") {
              res(balance);
            }
            else {
              FailHanler('Transection Failed')
              navigate('/')
            }
          });
        }
        else {
          FailHanler('You  dont have  balance')
          navigate('/')
        }
      });
    });
  };
  const RedeeAlert = () => {
    Swal.fire({
      title: "Diamond Redeemed Successfully!",
      html: `Redeem Request Received.<p>cUSD will be transferred to your minipay wallet account within 24 hours.</p>
      `,
      showDenyButton: false,
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      icon: "success"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
        navigate(`/progress/${0}`);
      }
    });
  }
  const InvalidRedeeAlert = () => {
    Swal.fire({
      title: "Invalid Diamond Reedem Request!",
      showDenyButton: false,
      confirmButtonText: "Ok",
      allowOutsideClick: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
        navigate("/Progress");
      }
    });
  }
  const redeem = async (quantity) => {
    SetClickvalue(true);
    try {
      const endpoint = 'offer/redeemUserDiamondApi/'
      const result = await Redeme(props.user_id, quantity, BASE_URL, endpoint, props.tok);
      console.log('redeem', result);
      if (result.result == 'Invalid Reedem Request') {
        InvalidRedeeAlert()
      }
      else {
        RedeeAlert()
      }
    }
    catch { }
  }

  return (
    <>
      {Buy && <div>
        <h1>fheiufy</h1>
      </div>}
      {ClickValue && load == true && <div className='center_vh'>
        <img src={loader} alt="loader"></img>
      </div>
      }
      {!ClickValue && !load &&
        <div className="coin_inf_p">
          <div className="coin_img_p_left">
            <img src={props.limage} alt="coin bg" />
          </div>
          <div className="wallet_pg">
            <p className="ttl_txt">{props.name}</p>
            {props.status == 1 && <h2 className="ttl_balance">
              <img src={props.image} alt="coin" />
              {props.value}
            </h2>}
            <div className='bal_new'>
              {props.status == 2 && <h2 className="ttl_balance">
                <img src={props.image} alt="coin" />

                {props.value}
              </h2>
              }
              {/* {props.status == 2 && <h2 className="ttl_balance">
                (<img src={dollar} alt="coin" />
                {(props.value) * 0.01} cUSD)
              </h2>
              } */}
            </div>
            {/* recommended by vibhu sir 17 oct 2024 */}
            {/* {props.status==1&& <p>Get 500 coin in cUSD 0.02 </p>}
        {props.status==2&& <p> 1 Diamonds = 0.01 cUSD</p>} */}
            {/* {props.status==1 &&  ClickValue==false &&
         <button className="btn_white btn_gren " onClick={() =>Test()}>Buy  Now</button>}
          {props.status==1&& ClickValue==true &&
         <button className="btn_white btn_gren disable" >Buy  Now</button>}
         {props.status==2 && props.value>0 &&  ClickValue==false&&
         <button className="btn_white btn_gren claim_btn" onClick={() => redeem(props.value)} >Claim Now</button>}
          {props.status==2 && props.value>=0 &&  ClickValue==true&&
         <button className="btn_white btn_gren claim_btn" onClick={() => redeem(props.value)}>Claim Now</button>}
         {props.status==2 && props.value<=0 &&
         <button className="btn_white btn_gren claim_btn disable" onClick={() => redeem(props.value)}>Claim Now</button>}  */}
          </div>
        </div>
      }
    </>
  )
}

export default WalletInfo
