import React from 'react'
import users from '../../images/play_solo.png'
import dollar from '../../images/celo_dollar.png'
import coin from '../../images/coin.png'
import dd from '../../images/diamond.png'
import { useState, useEffect } from 'react';
import MyActivity from '../Progress/MyActivity'
import { useNavigate } from "react-router-dom";
import { useLocation,useParams} from 'react-router-dom'
import ContestPlayed from '../Progress/ContestPlayed'
import ActiveContest from '../Progress/ActiveContest'
const Profile = (props) => {
    const data=props.User
    const name=props.name==null?'You':props.name
    const images=props.profilepic==null?users:props.profilepic
    const navigate = useNavigate()
    const [Value, setValue] = useState(0);
  const {state}=useLocation()
console.log('skhdkg',state)
     const Edit=()=>{
            const dataToSend = {
                name:name,
                pic:images
              };
        navigate("/Edit",{ state: dataToSend });
            }
  return (
  <>
        <div className='game_list'>
            <div class="game_details3 pro_marg">
                <div className='profile_m'>
                    <div className='profile_l'>
                    <img src={images} alt=''/>
                        <div class="w_70">
                            <p class="game_ttl">{name}</p> 
                            <p class="game_desc">**{props.user}</p>
                        </div>
                    </div>
                    <div className='right toggle_btn edit_bt'>
                        <button onClick={() => Edit()}>Edit Profile</button>
                    </div>
                </div>
            </div>
            <div className='info_profile'>
                <div className='game_details3 pro_marg info_pro_22'>
                    <p class="game_desc">Coins</p>
                    <p class="game_ttl ttl_img22"><img src={coin}></img>{props.Coin}</p>
                </div>
                <div className='game_details3 pro_marg info_pro_22'>
                    <p class="game_desc">Diamonds</p>
                    <p class="game_ttl ttl_img22"><img src={dd}></img>{props.daimond}</p>
                </div>
                <div className='game_details3 pro_marg info_pro_22 w_100'>
                    <p class="game_desc">Overall Earnings</p>
                    <p class="game_ttl ttl_img22"><img src={dollar}></img>{props.cUsd}</p>
                </div>
            </div>
            <div className="tab_n mrgin_10_tb">
                <div className='toggle_btn'>
                    <button className={`${(Value !=2 && Value !=3)  ? 'act_btn' : ''}`} onClick={() => setValue(1)}>My Activity</button>
                    <button className={`${Value == 2 ? 'act_btn' : ''}`} onClick={() => setValue(2)}>Contest Played</button>
                    <button className={`${(Value ==3)  ? 'act_btn' : ''}`} onClick={() => setValue(3)}>Active Contest</button>
                </div>
            </div>

            {(Value !==2&& Value !==3)&&
           <MyActivity Hist={props.Hist}></MyActivity>
            //   <LeadBoard type={1} contest={contest} lead={lead}></LeadBoard>
            }
             {Value === 2 &&
             <ContestPlayed arr={props.PlayedContest}></ContestPlayed>
            }
  {(Value==3)&&
             <ActiveContest arr={props.Active}></ActiveContest>
            }
        </div>
  </>
  )
}

export default Profile
